import { select, getThe, getTheBoolean } from './utils'
import { createSelector } from 'reselect'
import moment from 'moment'
import get from 'lodash/get'
import { selectHomeSoldDate } from './home'
import { selectLatestQuartileMarketValue } from './marketRecord'
import { MARKET_VALUE_BREAK_POINTS } from '../../constants/marketRecords'
import { GREEN } from '../../constants/shouldYouSell'

const getShouldYouSell = state => state.shouldYouSell
const selectFromShouldYouSell = select(getShouldYouSell)

export const selectShouldYouSellData = selectFromShouldYouSell(getThe('data'))

export const selectShouldYouSellLoading = selectFromShouldYouSell(getTheBoolean('loading'))

export const selectShouldYouSellError = selectFromShouldYouSell(getThe('error'))

// Appreciation selectors
export const selectAppreciation = selectFromShouldYouSell(getThe('appreciation'))

export const selectEquityValue = selectFromShouldYouSell(getThe('equityValue'))

export const selectAppreciationRelevancy = createSelector(selectAppreciation, appreciation =>
  get(appreciation, 'relevant')
)

export const selectAppreciationYearsOfData = createSelector(selectAppreciation, appreciation =>
  get(appreciation, 'yearsOfData')
)

export const selectAnnualAppreciationPercent = createSelector(selectAppreciation, appreciation =>
  get(appreciation, 'annualAppreciationPercent')
)

export const selectAnnualAppreciationValue = createSelector(selectAppreciation, appreciation =>
  get(appreciation, 'annualAppreciationValue')
)

export const selectAppreciationFiveYearEquity = createSelector(selectAppreciation, appreciation =>
  get(appreciation, 'fiveYearNetWorth')
)

export const selectAppreciationTenYearEquity = createSelector(selectAppreciation, appreciation =>
  get(appreciation, 'tenYearNetWorth')
)

export const selectAppreciationGrowthStatus = createSelector(selectAppreciation, appreciation =>
  get(appreciation, 'growthStatus')
)

export const selectAppreciationIndicatorStatus = createSelector(selectAppreciation, appreciation =>
  get(appreciation, 'indicatorStatus')
)

// Cash Position Selectors
export const selectCashPosition = selectFromShouldYouSell(getThe('cashPosition'))
export const selectCashPositionRelevant = createSelector(selectCashPosition, cashPosition =>
  get(cashPosition, 'relevant')
)

export const selectMinSellingPrice = createSelector(selectCashPosition, cashPosition =>
  get(cashPosition, 'minSellingPrice')
)

export const selectPercentDown = createSelector(selectCashPosition, cashPosition => get(cashPosition, 'percentDown'))

export const selectCashPositionEquity = createSelector(selectCashPosition, cashPosition => get(cashPosition, 'equity'))

export const selectCashPositionIndicatorStatus = createSelector(selectCashPosition, cashPosition =>
  get(cashPosition, 'indicatorStatus')
)

export const selectCashPositionGood = createSelector(
  selectCashPositionIndicatorStatus,
  indicatorStatus => indicatorStatus === GREEN
)

// Dynamic header selector
export const selectShouldYouSellHeaderIsPositive = createSelector(
  selectAppreciationIndicatorStatus,
  selectCashPositionIndicatorStatus,
  selectLatestQuartileMarketValue,
  (appreciationIndicatorStatus, cashPositionIndicatorStatus, marketValue) => {
    let count = 0

    if (appreciationIndicatorStatus === GREEN) {
      count++
    }

    if (cashPositionIndicatorStatus === GREEN) {
      count++
    }

    if (marketValue > MARKET_VALUE_BREAK_POINTS.HIGH) {
      count++
    }

    return count >= 2
  }
)

export const selectPositiveIndicatorsCount = createSelector(
  selectAppreciationIndicatorStatus,
  selectCashPositionIndicatorStatus,
  selectLatestQuartileMarketValue,
  (appreciationIndicatorStatus, cashPositionIndicatorStatus, marketValue) => {
    let count = 0

    if (appreciationIndicatorStatus === GREEN) {
      count++
    }

    if (cashPositionIndicatorStatus === GREEN) {
      count++
    }

    if (marketValue > MARKET_VALUE_BREAK_POINTS.HIGH) {
      count++
    }

    return count
  }
)

// Sold Date Disclaimer
export const selectRecentSoldDate = createSelector(selectHomeSoldDate, soldDate => {
  const now = moment()
  const then = moment(soldDate, 'YYYY-MM-DD')
  const diff = now.diff(then, 'years')

  return diff < 2
})
