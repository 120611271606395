import moment from 'moment'
import compact from 'lodash/compact'

export const calculateIndexHistoryByMonth = history => {
  if (!Array.isArray(history)) {
    return undefined
  }

  const compactedData = compact(history)
  const summedMonthGroup = compactedData.reduce((map, data) => {
    const key = moment(data.date).format('YYYY-MM')

    if (!map[key]) {
      map[key] = {
        month: key,
        sum: data.score,
        count: 1
      }
    } else {
      map[key].count += 1
      map[key].sum += data.score
    }

    return map
  }, {})

  return Object.values(summedMonthGroup)
    .map(summed => ({
      month: summed.month,
      value: Number((summed.sum / summed.count).toFixed(3))
    }))
    .sort((a, b) => (a.month > b.month ? 1 : -1)) // ascending
}

export const fetchLatestMarketValue = historicalMarketValues => {
  if (!historicalMarketValues || !Array.isArray(historicalMarketValues)) return undefined

  return historicalMarketValues[historicalMarketValues.length - 1].value
}
