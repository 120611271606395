export const getColorForMarketIndex = (value, color) => {
  if (value <= 20) {
    return document.documentElement.style.getPropertyValue(`${color}-cold`)
  }

  if (value >= 20 && value <= 40) {
    return document.documentElement.style.getPropertyValue(`${color}-cool`)
  }

  if (value >= 40 && value <= 60) {
    return document.documentElement.style.getPropertyValue(`${color}-neutral`)
  }

  if (value >= 60 && value <= 80) {
    return document.documentElement.style.getPropertyValue(`${color}-warm`)
  }

  return document.documentElement.style.getPropertyValue(`${color}-hot`)
}
