import qs from 'qs'
import client from './token_client'
import { transformHomePreview } from './token_transform'

export const fetchHomePreview = (id: string) =>
  client.get(`home-previews/${id}`).then(response => transformHomePreview(response.data.data))

export const fetchAgent = () => client.get('customer-profiles/identify')

interface FetchAirDnaDataParams {
  address: string
  zipcode: string
  validationToken: string
}

export const fetchAirDna = async ({
  address, // example format: 1234 fake road city, state 12345
  validationToken
}: FetchAirDnaDataParams) => {
  const params = qs.stringify({
    rental_potential: {
      address
    },
    validation_token: validationToken
  })

  const response = await client.post(`search/rental_potential?${params}`)

  return response
}

export const checkLead = (redirectUrl: string, email: string, locale: string) => {
  return client.get(
    `check_client_for_customer?redirect_url=${encodeURIComponent(redirectUrl)}&email=${encodeURIComponent(
      email
    )}&locale=${encodeURIComponent(locale)}`
  )
}

interface CreateLeadParams {
  address?: { street: string; zip: string }
  buyerCity: string
  firstName: string
  lastName: string
  email: string
  phone: string
  id: string
  leadSource: string
  inviterId: string
  validationToken: string
  hbbAccess: boolean
  locale: string
}

export const createLead = (data: CreateLeadParams) => {
  const postData = {
    attributes: {
      buyer_city: data.buyerCity,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      mobile: data.phone,
      id: data.id,
      lead_source: data.leadSource || 'leadgen_widget',
      locale: data.locale,
      inviter_id: data.inviterId,
      validation_token: data.validationToken,
      hbb_access: data.hbbAccess,
      address: {} // mutated below
    }
  }

  if (data.address) {
    postData.attributes.address = {
      street: data.address.street,
      zip: data.address.zip
    }
  }

  return client.post('/leads', {
    data: postData
  })
}

interface CreateNewBuyerLeadParams {
  email: string
  firstName: string
  lastName: string
  referrer?: string
  redirectUrl: string
  validationToken: string
  locale: string
  mobile?: string
}

export const createNewBuyerLead = (data: CreateNewBuyerLeadParams) => {
  const postData = {
    attributes: {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      locale: data.locale,
      lead_source:
        data.referrer && data.referrer !== 'undefined' // if 'ref' is empty in query string, it gets serialized as 'undefined'
          ? data.referrer
          : 'property_search',
      redirect_url: data.redirectUrl,
      validation_token: data.validationToken,
      ...(data.mobile && { mobile: data.mobile })
    }
  }

  return client.post('/create_buyer_lead', {
    data: postData
  })
}
