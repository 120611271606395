import { createSelector } from 'reselect'
import get from 'lodash/get'
import partialRight from 'lodash/partialRight'
import map from 'lodash/map'
import { Finance } from '../../quartermaster/src'
import { MONTHS_IN_YEAR, buildRefiKey } from '../../constants/refinance'
import { ARM_MORTGAGE_TYPE, FIX_MORTGAGE_TYPE, LT30 } from '../../constants/loan'
import {
  selectCELsOutstandingPrincipal,
  selectCELsPaymentForExistingLoans,
  calculateInterestPaidForExistingLoans
} from './home/loans'
import { RefiRate, RefiOption, RefiDetail } from '../../types/refinance'

// TODO: TS state and schedule interface
type State = any
type Schedule = any

export const selectStateBranch = (state: State) => state.refinance

export const latestMortgageRatesInput = (state: State) => state.latestMortgageRates
export const selectFromLatestMortgageRates = (path: string) =>
  createSelector<State, RefiRate[]>(latestMortgageRatesInput, partialRight(get, path))
export const selectLatestRates = selectFromLatestMortgageRates('data')

export const selectThirtyYearFixedRate = createSelector<State, RefiRate | undefined>(
  selectLatestRates,
  (latestRates: RefiRate[]) =>
    latestRates?.find((rate: RefiRate) => rate.mortgageType === FIX_MORTGAGE_TYPE && rate.term === LT30)
)

export const selectRefiOptions = createSelector<State, RefiOption[] | undefined>(
  selectLatestRates,
  selectCELsOutstandingPrincipal,
  calculateInterestPaidForExistingLoans,
  selectCELsPaymentForExistingLoans,
  (latestRates: RefiRate[], loanBalance: number, interestPaidOverRemainderOfLoans: number[], existingPayment: number) =>
    map(latestRates, (rate: RefiRate) =>
      generateRefiOption(rate, loanBalance, interestPaidOverRemainderOfLoans, existingPayment)
    )
)

export const getInterestPaidForRefiOption = (rate: RefiRate, schedule: Schedule, costs: number) => {
  const cardinality = rate.term * MONTHS_IN_YEAR
  const trimmedLoanSchedule = schedule.slice(0, cardinality)
  const interestPaidForRefiOption = Finance.calculateInterestPaidOverSchedule(trimmedLoanSchedule, costs)

  return interestPaidForRefiOption
}

export const selectFromRefinance = (path: string) =>
  createSelector<State, any>(selectStateBranch, partialRight(get, path))

export const generateRefiOption = (
  rate: RefiRate,
  loanBalance: number,
  interestOnExistingLoans: number[],
  currentLoanPayment: number
): RefiOption => {
  const periods = rate.term * MONTHS_IN_YEAR

  const costs = Finance.calculateLoanFees(loanBalance)
  const apr = Finance.calculateAPR(loanBalance, periods, parseFloat(rate.standardizedRate), costs)
  const ammoritizationObject = Finance.calculateAmortization(
    loanBalance + costs,
    rate.term * MONTHS_IN_YEAR,
    parseFloat(rate.standardizedRate)
  )
  const { schedule } = ammoritizationObject
  const diffPayment = get(schedule[0], 'payment') - currentLoanPayment
  const interestPaidOnRefiOption = getInterestPaidForRefiOption(rate, schedule, costs)
  const diffLoanAndRefi = calculateDiffLoanAndRefiOption(interestOnExistingLoans, interestPaidOnRefiOption)

  return {
    type: rate.mortgageType,
    term: rate.term,
    rate: parseFloat(rate.standardizedRate),
    apr,
    longTermSavings: diffLoanAndRefi,
    diffPayment,
    armYears: rate.armYearsInitial,
    closingCosts: costs
  }
}

export const calculateDiffLoanAndRefiOption = (interestPaidOnLoan: number[], interestPaidOnRefi: number[]) =>
  map(interestPaidOnLoan, (loanInterest: number, index: number) => Math.round(loanInterest - interestPaidOnRefi[index]))

export const selectRefiRequested = selectFromRefinance('refiRequested')
export const selectRefiRequestSuccess = selectFromRefinance('refiRequestSuccess')
export const selectRefiRequestFailure = selectFromRefinance('refiRequestFailure')

export const selectRefiYearsInHome = selectFromRefinance('yearsInHome')

export const SHORTTERM_SAVINGS_TYPE = FIX_MORTGAGE_TYPE
export const SHORTTERM_SAVINGS_TERM = LT30
export const selectRefiShorttermSavings = createSelector<State, number | undefined>(
  selectRefiOptions,
  (options: RefiOption[]) => {
    const fixed30 = options.find(({ type, term }) => type === SHORTTERM_SAVINGS_TYPE && term === SHORTTERM_SAVINGS_TERM)
    return fixed30 ? -1 * Math.round(fixed30.diffPayment) : undefined
  }
)
export const selectRefiShorttermSavingsKey = () =>
  buildRefiKey({
    years: SHORTTERM_SAVINGS_TERM,
    type: SHORTTERM_SAVINGS_TYPE
  })

export const selectRefiDetails = createSelector<State, RefiDetail[] | undefined>(
  selectRefiOptions,
  selectRefiYearsInHome,
  (options: RefiOption[], years: number) =>
    options
      .map(
        (option: RefiOption): RefiDetail => ({
          type: option.type,
          term: option.term,
          years: calcYearsForType(option),
          rate: option.rate,
          apr: option.apr,
          longterm: calcLongTermAtYear(
            option.longTermSavings,
            option.type === ARM_MORTGAGE_TYPE ? Math.min(option.armYears, years) : years
          ),
          shortterm: Math.round(option.diffPayment),
          closing: option.closingCosts,
          breakeven: calcBreakEvenIndex(option.longTermSavings),
          potential30Year: calcLongTermAtYear(option.longTermSavings, option.type === ARM_MORTGAGE_TYPE ? 30 : -1),
          datapoints: option.longTermSavings
            .map((saved, year) => {
              const cssSuffix = ['bar']
              const isArm = option.type === ARM_MORTGAGE_TYPE
              const isUnknown = isArm && year >= option.armYears

              if (isUnknown || year >= years) {
                cssSuffix.push('subtle')
              }

              if (isArm && year >= option.armYears) {
                cssSuffix.push('warning')
              } else if (saved > 0) {
                cssSuffix.push('positive')
              } else {
                cssSuffix.push('danger')
              }

              return {
                x: year + 1,
                y: isUnknown ? scrubSaved(option.longTermSavings, year, option.armYears) : saved,
                cssSuffix
              }
            })
            .slice(0, Math.max(option.term, years))
        })
      )
      .sort((a?: RefiDetail, b?: RefiDetail) => {
        if (a && b && a.longterm && b.longterm) {
          if (a.longterm > b.longterm) {
            return -1
          }
          if (a.longterm < b.longterm) {
            return 1
          }
        }
        return 0
      })
)

const scrubSaved = (savingsArray: number[], year: number, cutoff: number) => {
  const final = savingsArray[cutoff - 1]
  const base = final / 2
  const bumpArray = [-4, -1, 1, 4, 1, -1].map(x => x * 0.05)

  return base + base * bumpArray[(year - cutoff) % 6] * (final >= 0 ? 1 : -1)
}

const calcBreakEvenIndex = (savingsArray: number[]) => {
  const findIndex = savingsArray.findIndex((save, i, array) => {
    const firstPos = i === 0 && save > 0
    const prevNeg = i > 0 && array[i - 1] < 0
    const nowPos = array[i] >= 0

    return firstPos || (prevNeg && nowPos)
  })

  if (findIndex > -1) {
    return findIndex
  }

  return undefined
}

const calcLongTermAtYear = (savingsArray: number[], year: number) =>
  year > 0 && year <= savingsArray.length ? savingsArray[year - 1] : undefined

const calcYearsForType = (option: RefiOption) => (option.type === ARM_MORTGAGE_TYPE ? option.armYears : option.term)
