import pick from 'lodash/pick'
import mapKeys from 'lodash/mapKeys'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
export const PREPARE_BUYER = 3
export const PREPARE_HOMEOWNER = 14
export const SEARCH_HOMEOWNER = 14
export const CLOSE = 45

const OVERRIDE_KEYS = {
  buyerCloseDays: 'close',
  buyerPrepareDays: 'prepare'
}

const getCPValues = profile => {
  const picked = pick(profile, Object.keys(OVERRIDE_KEYS))
  const mapped = mapKeys(picked, (v, k) => OVERRIDE_KEYS[k])
  const omittedBy = omitBy(mapped, isNil)
  return omittedBy
}

const timeline = (quartile, customerProfile = {}, forHomeowner) => {
  const record = Object.assign(
    {
      prepare: forHomeowner ? PREPARE_HOMEOWNER : PREPARE_BUYER,
      search: forHomeowner ? SEARCH_HOMEOWNER : quartile.searchDuration,
      close: CLOSE
    },
    getCPValues(customerProfile)
  )

  record.total = record.prepare + record.search + record.close

  return record
}

export default timeline
