export const SHOULD_YOU_SELL_HIGH_GROWTH_STATUS = 'high'
export const SHOULD_YOU_SELL_MODERATE_GROWTH_STATUS = 'moderate'
export const SHOULD_YOU_SELL_SLOW_GROWTH_STATUS = 'slow'
export const SHOULD_YOU_SELL_RISKY_GROWTH_STATUS = 'risky'
export const GROWTH_STATUSES = [
  SHOULD_YOU_SELL_HIGH_GROWTH_STATUS,
  SHOULD_YOU_SELL_MODERATE_GROWTH_STATUS,
  SHOULD_YOU_SELL_SLOW_GROWTH_STATUS,
  SHOULD_YOU_SELL_RISKY_GROWTH_STATUS
]

// Indictor Statuses
export const GREEN = 'green'
export const ORANGE = 'orange'
export const YELLOW = 'yellow'
export const INDICATOR_STATUSES = [GREEN, ORANGE, YELLOW]

// Cash Position Constants
export const ABBREVIATION_BREAKPOINT = 10000

export const LOW_RANGE_PERCENT_DOWN = 5
export const UPPER_RANGE_PERCENT_DOWN = 81
export const PERCENT_DOWN_INCREMENTS = 5

export const LOWER_STEP_AMOUNT = 5000
export const UPPER_STEP_AMOUNT = 10000
export const STEP_VALUE_BREAKPOINT = 700000

export const NEW_HOME_MIN_PRICE = 40000

export const AVG_SELLING_FEELS = 0.07

export const UPPER_NEW_HOME_PRICE_PERCENT = 2.5

// Your Market Constants
export const MARKET_TEMP_KEYS = {
  HOTISH: 'hotish',
  BALANCED: 'balanced',
  COLDISH: 'coldish'
}
