import { createSelector } from 'reselect'
import curry from 'lodash/curry'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { selectFromAll } from '../dictionary'
import { selectHomeValue } from '../metadata'
import { selectCELsOutstandingPrincipal, selectCELsPaymentForExistingLoans } from '../loans'
import { avgSellingCostPercent, equitySearchTable, lowFeePercent, highFeePercent } from '../../../../constants/equity'
import { selectThirtyYearFixedRate } from '../../refinance'
import { selectCheapestMarket } from '../../marketRecord'
import { generatePriceBrackets, generateQualifiedListings } from '../../../../helpers/refinance'
import { selectEquitySmartSearchListings } from '../../listings'

export const getLeverageableEquityForLTV = curry((ltv, avm, balance) => avm * ltv - balance)

export const selectLeverageableEquityForLTV = ltv =>
  createSelector(selectHomeValue, selectCELsOutstandingPrincipal, getLeverageableEquityForLTV(ltv))

export const selectTotalEquity = selectLeverageableEquityForLTV(1)
export const selectLeverageableEquityForLTV80 = selectLeverageableEquityForLTV(0.8)

export const selectExistingMonthlyPayment = selectFromAll('monthlyPaymentTotal')

export const CASHOUT_LTV = 0.65
export const CASHOUT_MINIMUM = 25000
export const EMERGENCY_RESERVE_LTV = 0.6
export const EMERGENCY_RESERVE_MINIMUM = 10000
export const PURCHASING_POWER_LTV = 0.75
export const PURCHASING_POWER_MINIMUM = 10000

export const selectEmergencyReservePool = createSelector(
  selectLeverageableEquityForLTV(EMERGENCY_RESERVE_LTV),
  excess => excess - 0.06 * excess
)

export const selectSellingCost = feePercent => createSelector(selectHomeValue, avm => avm * feePercent)

export const selectLowFees = selectSellingCost(lowFeePercent)
export const selectHighFees = selectSellingCost(highFeePercent)

export const selectCashFromSale = createSelector(
  selectTotalEquity,
  selectSellingCost(avgSellingCostPercent),
  (totalEquity, sellingCost) => totalEquity - sellingCost
)

export const asDownPaymentPercent = curry((percent, downpayment) => downpayment / percent)

export const selectAddSpaceBudget = selectLeverageableEquityForLTV80
export const selectNewInvestmentBudget = selectLeverageableEquityForLTV80
export const selectHomeImprovementBudget = selectLeverageableEquityForLTV80

export const selectCashAfterSelling = createSelector(
  selectTotalEquity,
  selectSellingCost(equitySearchTable.SELLING_COST_PERCENT),
  (equity, sellingFees) => {
    return Math.round(equity - sellingFees)
  }
)

export const selectInitialSuggestedPurchasePrice = createSelector(selectCashAfterSelling, cashAfterSelling => {
  const newPossibleDownPayment = cashAfterSelling - equitySearchTable.MINIMUM_POCKETED_CASH
  return Math.round(newPossibleDownPayment * 5)
})

export const selectInitialPriceStepDown = createSelector(selectHomeValue, homeValue => {
  let stepDownAmt = ''
  if (homeValue <= equitySearchTable.FIRST_BREAK_POINT) {
    stepDownAmt = equitySearchTable.FIRST_STEP_DOWN_AMT
  } else if (homeValue > equitySearchTable.FIRST_BREAK_POINT && homeValue <= equitySearchTable.SECOND_BREAK_POINT) {
    stepDownAmt = equitySearchTable.SECOND_STEP_DOWN_AMT
  } else {
    stepDownAmt = equitySearchTable.THIRD_STEP_DOWN_AMT
  }

  return stepDownAmt
})

export const selectPriceBrackets = createSelector(
  selectCheapestMarket,
  selectCELsPaymentForExistingLoans,
  selectTotalEquity,
  selectHomeValue,
  selectInitialPriceStepDown,
  selectInitialSuggestedPurchasePrice,
  selectThirtyYearFixedRate,
  (
    cheapestMarket,
    currentLoanPayment,
    equity,
    homeValue,
    initialPriceStepDownAmt,
    suggestedInitialPurchasePrice,
    thirtyYearFixedRate
  ) => {
    const DEFAULT_STEP_DOWN = 0.95
    const price =
      suggestedInitialPurchasePrice > homeValue
        ? Math.round(homeValue * DEFAULT_STEP_DOWN)
        : suggestedInitialPurchasePrice

    return generatePriceBrackets({
      cheapestMarket,
      currentLoanPayment,
      equity,
      homeValue,
      initialPriceStepDownAmt,
      initialPurchasePrice: price,
      thirtyYearFixedRate
    })
  }
)

export const selectQualifiedEquityListings = createSelector(
  selectHomeValue,
  selectTotalEquity,
  selectEquitySmartSearchListings,
  selectThirtyYearFixedRate,
  selectCELsPaymentForExistingLoans,
  (homeValue, equity, listings, thirtyYearAvgRate, currentLoanPayment) => {
    if (isEmpty(listings)) {
      return listings
    }

    return generateQualifiedListings({
      homeValue,
      equity,
      listings,
      thirtyYearAvgRate,
      currentLoanPayment
    })
  }
)

export const selectFirstPriceBracket = createSelector(selectPriceBrackets, priceBrackets => {
  if (!priceBrackets) return {}

  return get(priceBrackets, ['0'])
})

export const selectFirstPriceBracketPocketAmount = createSelector(selectPriceBrackets, brackets => {
  return get(brackets, ['0', 'pocketAmount'], 0)
})
