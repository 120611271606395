// eslint-disable-next-line no-restricted-imports
import { CurriedFunction1 } from 'lodash'
import { createSelector } from 'reselect'
import { Finance } from '../../../../quartermaster/src'
import { selectNewPrimaryResidenceMortgageRate } from '../../mortgageRate'
import { rentBuy, rentalProperty } from '../../../../constants/equity'
import { selectLeverageableEquityForLTV80, asDownPaymentPercent } from './equity'

type State = any // TODO: TS build state interface

export const selectNewPrimaryResidenceDownPayment = selectLeverageableEquityForLTV80

export const selectNewPrimaryResidenceBudget = createSelector<State, number>(
  selectNewPrimaryResidenceDownPayment,
  (downpayment: number) => {
    const curriedFunc: (next: number) => CurriedFunction1<any, number> = asDownPaymentPercent
    return curriedFunc(rentBuy.downPaymentPercent)(downpayment)
  }
)

const selectBudgetAfterDownpayment = createSelector<State, number>(
  selectNewPrimaryResidenceBudget,
  selectNewPrimaryResidenceDownPayment,
  (budget: number, downpayment: number) => budget - downpayment
)

export const selectNewPrimaryResidenceMonthlyPayment = createSelector<State, number>(
  selectBudgetAfterDownpayment,
  selectNewPrimaryResidenceMortgageRate,
  (budget: number, rate: number) => Finance.calculatePayment(budget, rentalProperty.loanTerm * 12, rate)
)
