import * as Sentry from '@sentry/browser'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import { getColorForMarketIndex } from '../../../../helpers/withColorIndex'
import distance from '../../../../helpers/distance'
import { METADATA_SCOPES } from '../../../../constants/marketRecords'
import getQuartile from './quartile'
import sqftAfford from './sqftAfford'
import getTimeline from './timeline'

export const SINGLE_FAMILY = 'singleFamily'
export const MULTI_FAMILY = 'multiFamily'

const MARKET_PREFERENCE = [SINGLE_FAMILY, MULTI_FAMILY]

const SUFFICIENT_COVERAGE_SCORE = 30

const preferredMarketType = (market, targetType) => {
  const coveredTypes = MARKET_PREFERENCE.filter(type => market.coverageScore[type] >= SUFFICIENT_COVERAGE_SCORE)

  if (!targetType && coveredTypes.length) {
    return coveredTypes[0]
  }

  if (targetType && coveredTypes.includes(targetType)) {
    return targetType
  }

  return null
}

const neighborhoods = market => {
  const filtered = filter(market.neighborhoods, ['subType', 'N'])
  const sorted = sortBy(filtered, neighborhood => distance(neighborhood.coords, market.zipcodeCoords))
  return sorted
}

const metadata = params => {
  const { market, affordability, customerProfile, targetType, scope, useHomeownerTimeline } = params

  if (!market || !market.coverageScore) {
    // sentry.io/homebot/buyer/issues/862622094
    Sentry.addBreadcrumb({
      level: 'info',
      category: 'market metadata',
      data: { market, affordability }
    })
    return {}
  }

  const marketType = preferredMarketType(market, targetType)
  if (!marketType) {
    return {}
  }

  const coverageScore = market.coverageScore[marketType]
  const quartile = getQuartile(affordability, marketType, market)
  const composite = market[`${marketType}Composite`]

  const data = scope === METADATA_SCOPES.COMPOSITE ? composite : quartile
  if (!data) {
    return {}
  }

  const marketIndex = data.score
  const filteredNeighborhoods = neighborhoods(market)

  return {
    data,
    marketType,
    marketIndex,
    neighborhoods: filteredNeighborhoods,
    enoughData: coverageScore > SUFFICIENT_COVERAGE_SCORE,
    sqftAfford: sqftAfford(affordability, data, marketType, market),
    timeline: getTimeline(data, customerProfile, useHomeownerTimeline),
    marketIndexColor: getColorForMarketIndex(marketIndex, '--brand-market-temp')
  }
}

export default metadata
