import { createSelector } from 'reselect'
// eslint-disable-next-line no-restricted-imports
import { CurriedFunction2 } from 'lodash'
import find from 'lodash/find'
import get from 'lodash/get'
import { Finance } from '../../../../quartermaster/src'
import { selectHomeValue } from '../metadata'
import { selectRentalPropertyMortgageRate } from '../../mortgageRate'
import { selectAmortization } from '../finance'
import { rentalProperty } from '../../../../constants/equity'
import { selectLeverageableEquityForLTV, getLeverageableEquityForLTV, asDownPaymentPercent } from './equity'

type State = any

const asDownPaymentPercent2: (one: number, two: number) => number = asDownPaymentPercent
const getLeverageableEquityForLTV1: (next: number) => CurriedFunction2<number, number, number> =
  getLeverageableEquityForLTV

export const selectRentalPropertyDownPayment = selectLeverageableEquityForLTV(rentalProperty.targetLTV)

export const selectRentalPropertyBudget = createSelector<State, number>(
  selectLeverageableEquityForLTV(rentalProperty.targetLTV),
  (equity: number) => asDownPaymentPercent2(rentalProperty.downPaymentPercent, equity)
)

export const selectRentalPropertyMinimum = () =>
  asDownPaymentPercent2(rentalProperty.downPaymentPercent, rentalProperty.minimumEquity)

const selectBudgetAfterDownpayment = createSelector<State, number>(
  selectRentalPropertyBudget,
  selectRentalPropertyDownPayment,
  (budget: number, downpayment: number) => budget - downpayment
)

export const selectRentalPropertyMonthlyPayment = createSelector(
  selectBudgetAfterDownpayment,
  selectRentalPropertyMortgageRate,
  (budget, rate) => Finance.calculatePayment(budget, rentalProperty.loanTerm * 12, rate)
)

const getLeverageableEquityForRental: CurriedFunction2<number, number, number> = getLeverageableEquityForLTV1(
  rentalProperty.targetLTV
)

const selectAmortizationEntryWithEnoughEquityForRentalProperty = createSelector(
  selectAmortization,
  selectHomeValue,
  (amortization: { schedule: object }, avm: number) =>
    find(
      amortization.schedule,
      ({ balance }: { balance: number }) => rentalProperty.minimumEquity < getLeverageableEquityForRental(avm, balance)
    )
)

export const selectRentalPropertyDateOfTargetLTV = createSelector<State, string>(
  selectAmortizationEntryWithEnoughEquityForRentalProperty,
  (entry: object) => get(entry, 'date')
)

export const selectRentalPropertyFutureEquity = createSelector<State, number>(
  selectAmortizationEntryWithEnoughEquityForRentalProperty,
  selectHomeValue,
  (entry: { balance: number }, avm: number) => entry && getLeverageableEquityForRental(avm, entry.balance)
)

export const selectRentalPropertyFutureBudget = createSelector<State, number>(
  selectRentalPropertyFutureEquity,
  (equity: number) => asDownPaymentPercent2(rentalProperty.downPaymentPercent, equity)
)
