import { useSelector } from 'react-redux'
import {
  BoundingBoxLevel,
  GeoAreaLevel,
  ListingsForAreaQueryVariables,
  ListingsOrderBy,
  ListingsOrderDirection,
  PropertyType
} from '../../../../api/gqlaxy/gql/generated/graphql'
import { selectFilter } from '../../../../store/selectors/filters'
import { useEffect, useMemo, useState } from 'react'
import { listingsSearchCriteria } from '../../../../constants/listings'
import { SORT_VALUES } from '../ListingsSort/ListingsSort'
import { useRecoilState } from 'recoil'
import * as Sentry from '@sentry/browser'
import { currentSearchLocation } from '../../GoogleAutoComplete/state/currentSearchLocation'
import { currentSelectedLocation } from '../../GoogleAutoComplete/state/currentSelectedLocation'

export interface UseFiltersReturn {
  filters: Opt<ListingsForAreaQueryVariables>
  setFilters: (filters: Opt<ListingsForAreaQueryVariables>) => void
  page: number
  setPage: (page: number) => void
}

export const useFilters = () => {
  const perPage = listingsSearchCriteria.SEARCH_RESULTS_PAGE_LIMIT
  const filter = useSelector(selectFilter)
  const [searchLocation] = useRecoilState(currentSearchLocation)
  const [selectedLocation] = useRecoilState(currentSelectedLocation)
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState<Opt<ListingsForAreaQueryVariables>>()

  const getSortOrder = (sort: string) => {
    switch (sort) {
      case SORT_VALUES.sqftAsc:
        return { orderBy: ListingsOrderBy.Sqft, orderDir: ListingsOrderDirection.Asc }
      case SORT_VALUES.sqftDesc:
        return { orderBy: ListingsOrderBy.Sqft, orderDir: ListingsOrderDirection.Desc }
      case SORT_VALUES.acresAsc:
        return { orderBy: ListingsOrderBy.Acres, orderDir: ListingsOrderDirection.Asc }
      case SORT_VALUES.acresDesc:
        return { orderBy: ListingsOrderBy.Acres, orderDir: ListingsOrderDirection.Desc }
      case SORT_VALUES.priceAsc:
        return { orderBy: ListingsOrderBy.Price, orderDir: ListingsOrderDirection.Asc }
      case SORT_VALUES.priceDesc:
        return { orderBy: ListingsOrderBy.Price, orderDir: ListingsOrderDirection.Desc }
      case SORT_VALUES.daysOnMarket:
        return { orderBy: ListingsOrderBy.DaysOnMarket, orderDir: ListingsOrderDirection.Asc }
      default:
        return { orderBy: ListingsOrderBy.DaysOnMarket, orderDir: ListingsOrderDirection.Asc }
    }
  }

  const searchArea = useMemo(() => {
    if (
      !searchLocation &&
      filter.boundingBox?.bottom_right?.lat &&
      filter.boundingBox?.bottom_right?.lon &&
      filter.boundingBox?.top_left?.lat &&
      filter.boundingBox?.top_left?.lon
    ) {
      return {
        boundingBox: {
          bottomRight: {
            latitude: filter.boundingBox.bottom_right.lat,
            longitude: filter.boundingBox.bottom_right.lon
          },
          topLeft: {
            latitude: filter.boundingBox.top_left.lat,
            longitude: filter.boundingBox.top_left.lon
          },
          level: BoundingBoxLevel.BoundingBox
        }
      }
    } else {
      Sentry.addBreadcrumb({
        message: 'No bounding box found',
        data: {
          boundingBox: filter.boundingBox
        }
      })
    }

    if (searchLocation !== undefined && searchLocation !== null) {
      return {
        placeIdInput: {
          placeId: searchLocation.place_id
        }
      }
    } else {
      Sentry.addBreadcrumb({
        message: 'No location found',
        data: {
          searchLocation
        }
      })
    }

    if (
      filter.location.stateAbbreviation &&
      typeof filter.location.lat === 'number' &&
      typeof filter.location.lon === 'number'
    ) {
      if (filter.location.city) {
        return {
          geoArea: {
            name: filter.location.name,
            stateAbbreviation: filter.location.stateAbbreviation,
            city: filter.location.city,
            latitude: filter.location.lat,
            longitude: filter.location.lon,
            level: GeoAreaLevel.City
          }
        }
      } else {
        return {
          geoArea: {
            name: filter.location.name,
            stateAbbreviation: filter.location.stateAbbreviation,
            latitude: filter.location.lat,
            longitude: filter.location.lon,
            level: GeoAreaLevel.State
          }
        }
      }
    } else {
      Sentry.addBreadcrumb({
        message: 'No location found',
        data: {
          selectedLocation
        }
      })
    }
    return null
  }, [searchLocation, filter.boundingBox])

  useEffect(() => {
    // if (searchArea) {
    // console.log('hasSearchArea', searchArea)
    setFilters({
      searchArea: searchArea ?? {},
      perPage,
      page,
      sortOrder: getSortOrder(filter.order),
      filters: {
        statuses: filter.statuses,
        maxPrice: filter.maxPrice,
        minPrice: filter.minPrice,
        maxAcres: filter.maxAcres,
        minAcres: filter.minAcres,
        maxSqft: filter.maxSqft,
        minSqft: filter.minSqft,
        minYearBuilt: filter.minYearBuilt ? Number(filter.minYearBuilt) : null,
        maxYearBuilt: filter.maxYearBuilt ? Number(filter.maxYearBuilt) : null,
        minBathroomsCount: filter.minBathroomsCount,
        minBedroomsCount: filter.minBedroomsCount,
        propertyTypes: filter.propertyTypes as PropertyType[],
        hasSellerConcession: filter.hasSellerConcession === 'true',
        isAssumable: filter.isAssumable === 'true',
        ...((filter?.monthlyExpensesInput?.maxMonthlyExpenses &&
          filter?.monthlyExpensesInput.downpaymentAmountDollars) ||
        (filter?.monthlyExpensesInput?.minMonthlyExpenses && filter?.monthlyExpensesInput.downpaymentAmountDollars)
          ? {
              monthlyExpensesInput: {
                maxMonthlyExpenses: filter?.monthlyExpensesInput?.maxMonthlyExpenses,
                minMonthlyExpenses: filter?.monthlyExpensesInput?.minMonthlyExpenses,
                downpaymentAmountDollars: filter?.monthlyExpensesInput?.downpaymentAmountDollars
              }
            }
          : {})
      }
    })
    // }
    // else {
    //   console.log('has NO SearchArea')

    //   Sentry.addBreadcrumb({
    //     message: 'No search area found',
    //     data: {
    //       searchArea
    //     }
    //   })
    // }
  }, [filter, perPage, page, searchArea])

  return {
    filters,
    setFilters,
    setPage,
    page
  }
}
