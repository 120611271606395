const emptyQuartile = () => ({
  marketActionMedian: 0,
  percentPriceIncreasedMedian: 0,
  inventoryTotal: 0,
  priceMax: 0,
  monthsOfInventoryMedian: 0,
  estimatedSalesTotal: 0,
  priceOfListingsAbsorbedMedian: 0,
  priceOfNewListingsMedian: 0,
  bathsMean: 0,
  listingsAbsorbedTotal: 0,
  magnitudePriceIncreasedMedian: 0,
  daysOnMarketMedian: 0,
  magnitudePriceDecreasedMedian: 0,
  priceMin: 0,
  searchDuration: 0,
  bedsMean: 0,
  score: 0,
  priceMedian: 0,
  apartmentRentalPriceMedian: 0,
  newListingsTotal: 0,
  ageMean: 0,
  sqftMedian: 0,
  daysOnMarketMean: 0,
  percentPriceDecreasedMedian: 0
})

export default emptyQuartile
