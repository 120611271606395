export const mockAirDnaResponse = () => {
  const shape = {
    data: {
      payload: {
        stats: {
          future: {
            summary: {
              adr: 150.25,
              occupancy: 0.75,
              revenue: 45000
            }
          }
        }
      }
    }
  }

  const date = new Date()
  const currentMonth = date.getMonth() + 1
  const currentYear = date.getFullYear()
  shape.data.payload.stats.future.metrics = []
  const j = 0

  if (currentMonth !== 12) {
    for (let i = currentMonth + 1; i <= 12; i++) {
      shape.data.payload.stats.future.metrics[j] = {
        date: `${currentYear}-${i}`,
        occupancy: getRandomFloat(0, 1),
        adr: getRandomFloat(100, 200),
        revenue: getRandomInt(2000, 3000)
      }

      j++
    }
  }

  for (let i = 1; i <= currentMonth; i++) {
    shape.data.payload.stats.future.metrics[j] = {
      date: `${currentYear + 1}-${i}`,
      occupancy: getRandomFloat(0, 1),
      adr: getRandomFloat(100, 200),
      revenue: getRandomInt(2000, 3000)
    }

    j++
  }

  return shape
}

function getRandomInt(min, max) {
  return Math.round(getRandom(min, max))
}

function getRandomFloat(min, max) {
  return Math.round(getRandom(min, max) * 100) / 100
}

function getRandom(min, max) {
  return Math.random() * (max - min) + min
}
