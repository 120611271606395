import { createSelector } from 'reselect'
import { Finance } from '../../../quartermaster/src'
import { selectFromAll } from './dictionary'

export const selectAmortization = createSelector(
  selectFromAll('originalLoanAmountTotal'),
  selectFromAll('periods'),
  selectFromAll('interestRate'),
  selectFromAll('originalLoanDate'),
  Finance.calculateAmortization.bind(Finance)
)
