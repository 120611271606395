import { compose, lifecycle, setDisplayName } from 'recompose'
import { withRouter } from 'react-router'
import delay from 'lodash/delay'
import { scrollToAnchor } from '../helpers/browser'
import { shouldAutoScroll } from './scrollUtils'

const scrollToAnchorOnMount = compose(
  setDisplayName('scrollToAnchorOnMount'),
  withRouter,
  lifecycle({
    componentDidMount() {
      const { history } = this.props

      if (!shouldAutoScroll(location, history)) {
        return
      }

      delay(() => {
        scrollToAnchor(history.location.hash.slice(1))
      }, 500)
    }
  })
)

export default scrollToAnchorOnMount
