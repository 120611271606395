export const QueryKey = {
  GetFavoriteListings: 'FavoriteListings',
  GetListingEnriched: 'ListingEnriched',
  GetSavedSearches: 'SavedSearches',
  GetListingsForArea: 'ListingsForArea',
  GetUserFeeds: 'Feeds',
  GetFeedForZipCode: 'EphemeralFeed',
  GetFeed: 'Feed',
  GetPreferredAgent: 'PreferredAgent'
}
