import { combineReducers } from 'redux'

import affordability from './affordability'
import airDna from './airDna'
import auth from '../store/slices/auth'
import bombBomb from '../store/slices/bombBomb'
import brandingStats from './brandingStats'
import buyerInfo from './buyerInfo'
import buyerOnboarding from './buyerOnboarding'
import client from './client'
import clientEvents from './clientEvents'
import customerProfile from './customerProfile'
import customMailer from './customMailer'
import { extraPrincipalPaymentSavingsApi } from '../store/slices/extraPrincipalPaymentSavings'
import { favoriteListingsApi } from '../store/slices/favoriteListings'
import filters from './filters'
import home from './home'
import homeCreate from './homeCreate'
import homeInsurance from './homeInsurance'
import homeowner from './homeowner'
import homePreview from './homePreview'
import homeSearchAuthModal from './homeSearchAuthModal'
import invite from './invite'
import latestMortgageRates from './latestMortgageRates'
import layout from './layout'
import lead from './lead'
import listings from './listings'
import loan from './loan'
import locale from './locale'
import mapbox from './mapbox'
import marketRecords from './marketRecords'
import modals from '../store/slices/modals'
import mortgageCoach from './mortgageCoach'
import mortgageRates from './mortgageRates'
import navigation from '../store/slices/navigation'
import notification from './notification'
import pmi from '../store/slices/pmi'
import powerUps from './powerUps'
import recommendations from './recommendations'
import refinance from './refinance'
import scheduleTour from '../store/slices/scheduleTour'
import shouldYouSell from './shouldYouSell'
import sponsor from './sponsor'
import tca from './tca'
import toasts from './toasts'
import trackingSource from './trackingSource'
import transitions from './transitions'
import valuation from './valuation'
import valuationHistory from './valuationHistory'
import videos from './videos'
import zipModal from './zipModal'

export default combineReducers({
  affordability,
  airDna,
  auth,
  bombBomb,
  brandingStats,
  buyerInfo,
  buyerOnboarding,
  client,
  clientEvents,
  customerProfile,
  customMailer,
  [extraPrincipalPaymentSavingsApi.reducerPath]: extraPrincipalPaymentSavingsApi.reducer,
  [favoriteListingsApi.reducerPath]: favoriteListingsApi.reducer,
  home,
  homeCreate,
  homeInsurance,
  homeowner,
  homePreview,
  homeSearchAuthModal,
  invite,
  latestMortgageRates,
  layout,
  lead,
  listings,
  filters,
  loan,
  locale,
  mapbox,
  marketRecords,
  modals,
  mortgageCoach,
  mortgageRates,
  navigation,
  notification,
  pmi,
  powerUps,
  recommendations,
  refinance,
  scheduleTour,
  shouldYouSell,
  sponsor,
  tca,
  toasts,
  trackingSource,
  transitions,
  valuation,
  valuationHistory,
  videos,
  zipModal
})
