// eslint-disable-next-line no-restricted-imports
import { CurriedFunction1 } from 'lodash'
import { createSelector } from 'reselect'
import { Finance } from '../../../../quartermaster/src'
import { selectNewHomeMortgageRate } from '../../mortgageRate'
import { newHome } from '../../../../constants/equity'
import { selectHomeValue } from '../metadata'
import { selectCashFromSale, asDownPaymentPercent } from './equity'

// TODO: TS build state interface
type State = any

const asDownPaymentPercent1: (next: number) => CurriedFunction1<any, number> = asDownPaymentPercent

export const selectNewHomeTotalEquity = selectCashFromSale

export const selectNewHomeBudget = createSelector<State, number>(selectNewHomeTotalEquity, (equity: number) =>
  asDownPaymentPercent1(newHome.downPaymentPercent)(equity)
)

export const selectBuyerNewHomeBudgetWithCap = createSelector(
  selectNewHomeBudget,
  selectHomeValue,
  (budget, homeValue) => {
    // The budget here is not actually the budget, but it calculates the absolute max
    // home price you could "afford" with the equity in your home and using
    // ALL of that equity as the 20% down payment on a new mortgage while financing the rest.
    // Read this slack thread for exact context, examples, and calculations:
    // https://homebot.slack.com/archives/C02BF02JNGY/p1729026747916819
    //
    // Here is the formula: ( ( home value - outstanding loan balances ) - ( home value x 7% (avg. selling fees) ) ) / 20% ( down payment )
    //
    // Godspeed, you brave soul.

    const budgetCap = homeValue * newHome.budgetCap // cap at 150% of homeValue
    if (!budget || !homeValue) {
      return undefined
    }
    return budget <= budgetCap ? budget : budgetCap
  }
)

export const selectEightyLTVNewHomeMonthlyPayment = createSelector<State, number>(
  selectBuyerNewHomeBudgetWithCap,
  selectNewHomeMortgageRate,
  (budget, rate) => Finance.calculatePayment(budget * (1 - newHome.downPaymentPercent), newHome.loanTerm * 12, rate)
)
