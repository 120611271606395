import Fuse from 'fuse.js'
import get from 'lodash/get'
import partialRight from 'lodash/partialRight'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import memoize from 'lodash/memoize'
import map from 'lodash/map'
import compact from 'lodash/compact'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import head from 'lodash/head'
import { createSelector } from 'reselect'
import { matchPath } from 'react-router'
import store from '../..'
import getMetadata, { SINGLE_FAMILY, MULTI_FAMILY } from './metadata'
import { METADATA_SCOPES, MARKET_DEMAND_KEYS, MARKET_VALUE_BREAK_POINTS } from '../../../constants/marketRecords'
import { selectCustomerProfile } from '../customerProfile'
import { selectClientHomeZipcodes } from '../client'
import { getCurrentPath } from '../../../helpers/browser'
import { selectAffordability as selectBuyerAffordability } from '../buyerInfo'
import { selectHomeValue as selectHomeownerHomeValue } from '../home/metadata'
import { selectZipcode, selectHomeUnit } from '../home'
import { PATH_HELPERS } from '../../../constants/navigation'
import { every } from '../utils'
import { calculateIndexHistoryByMonth, fetchLatestMarketValue } from '../../../helpers/marketRecords'
import { selectActiveNavigationTab } from '../../slices/navigation'
import { NAVIGATION_TAB_NAMES } from '../../../types/navigation'

const marketSearcher = new Fuse([], {
  includeMatches: true,
  threshold: 0.2,
  minMatchCharLength: 3,
  keys: ['city', 'zipcode', 'neighborhoods.name']
})

export const selectMarketRecordScope = state => state.marketRecords.metadataScope

export const selectBuyerMarkets = createSelector(
  state => state.buyerInfo.marketInterests,
  state => state.marketRecords.zipMap,
  (marketInterests, zipMap) => {
    const mapped = map(marketInterests, ({ zipcode }) => zipMap[zipcode])
    const compacted = compact(mapped)
    return compacted
  }
)

export const selectBuyerMarketsLoading = createSelector(
  state => state.buyerInfo.marketInterests,
  selectBuyerMarkets,
  (marketInterests, buyerMarkets) => !(marketInterests && buyerMarkets)
)

export const selectVisibleMarkets = createSelector(
  state => state.zipModal.visibleZips,
  state => state.marketRecords.zipMap,
  (zips, zipMap) => {
    const mapped = map(zips, zip => zipMap[zip])
    const compacted = compact(mapped)
    return compacted
  }
)

const memoMarketMetadata = memoize(market =>
  createSelector(
    selectAffordabilityForMarketMetadata,
    selectCustomerProfile,
    selectHomeMarketType,
    selectActiveNavigationTab,
    (affordability, customerProfile, targetType, tab) => {
      const params = {
        market,
        affordability,
        customerProfile: customerProfile || {},
        targetType: tab === NAVIGATION_TAB_NAMES.HOMES ? targetType : null,
        useHomeownerTimeline: tab === NAVIGATION_TAB_NAMES.HOMES
      }

      return {
        [METADATA_SCOPES.COMPOSITE]: getMetadata({
          ...params,
          scope: METADATA_SCOPES.COMPOSITE
        }),
        [METADATA_SCOPES.QUARTILE]: getMetadata({
          ...params,
          scope: METADATA_SCOPES.QUARTILE
        })
      }
    }
  )
)

// Favorite must occur outside the above selector for update and performance reasons
// If favorite is inside the above, it will not update when favorite status changes
// as the market record does not change
const metaDataWithFavorite = market => state => {
  const metadata = memoMarketMetadata(market)(state)
  metadata.isFavorite = market && isFavoriteMarket(market)
  metadata.isInhabited = market && isInhabitedMarket(market)
  return metadata
}

// TODO: does not return selector, rename getMarketMetadata (or replace entirely)
export const selectMarketMetadata = (market, scope = METADATA_SCOPES.QUARTILE, _pricepoint) =>
  market ? metaDataWithFavorite(market)(store.getState())[scope] : {}

export const isFavoriteMarket = market => selectBuyerMarkets(store.getState()).includes(market)

export const isInhabitedMarket = market => selectClientHomeZipcodes(store.getState()).includes(market.zipcode)

const SORT_BY = {
  score: ['metadata.data.score', 'desc'],
  sqft: ['metadata.sqftAfford', 'desc'],
  price: ['metadata.data.priceMedian', 'asc'],
  timeline: ['metadata.data.searchDuration', 'asc']
}

const getSortedMarkets = (sort, markets) => {
  const [target, dir] = SORT_BY[sort]

  const marketsWithData = markets.map(market => ({
    market,
    metadata: selectMarketMetadata(market)
  }))

  const ordered = orderBy(marketsWithData, [target, 'market.zipcode'], [dir, 'asc'])
  const grouped = groupBy(ordered, ({ metadata }) => metadata.coverageScore > 30)

  const formatGrouped = (grouped = {}) => [
    ...(grouped.true || []),
    ...orderBy(grouped.false || [], 'metadata.coverageScore', 'desc')
  ]

  const formattedGrouped = formatGrouped(grouped)
  const mapped = map(formattedGrouped, 'market')
  const compacted = compact(mapped)
  return compacted
}

export const selectSortedVisibleMarkets = createSelector(
  state => state.zipModal.sort,
  selectVisibleMarkets,
  getSortedMarkets
)

export const selectSortedFavoriteMarkets = createSelector(
  state => state.zipModal.sort,
  selectBuyerMarkets,
  getSortedMarkets
)

export const selectFilteredMarkets = createSelector(
  state => selectSortedVisibleMarkets(state),
  state => state.zipModal.filterQuery,
  (markets, filterQuery) => {
    if (filterQuery && filterQuery.length > 2) {
      marketSearcher.setCollection(markets)
      marketSearcher.options.minMatchCharLength = filterQuery.length

      return marketSearcher.search(filterQuery)
    }

    return null
  }
)

export const selectAffordabilityForMarketMetadata = createSelector(
  selectActiveNavigationTab,
  selectBuyerAffordability,
  selectHomeownerHomeValue,
  (tab, buyerNumber, homeownerNumber) => {
    switch (tab) {
      case NAVIGATION_TAB_NAMES.HOMES:
        return homeownerNumber

      default:
        return buyerNumber
    }
  }
)

export const selectHomeMarketType = createSelector(selectHomeUnit, unit => (unit ? MULTI_FAMILY : SINGLE_FAMILY))

// TODO: dedupe with Map/helpers
export const selectCurrentZipcode = createSelector(
  selectActiveNavigationTab,
  getCurrentPath,
  selectZipcode,
  (tab, path, zip) => {
    const match = matchPath(path, {
      path: PATH_HELPERS.zip.matchPath
    })

    switch (tab) {
      case NAVIGATION_TAB_NAMES.HOMES:
        return zip

      default:
        return get(match, 'params.zipcode')
    }
  }
)

export const selectZipMap = state => get(state, 'marketRecords.zipMap')

export const selectMarket = zipcode => createSelector(selectZipMap, map => get(map, zipcode))

export const selectMetadataByZip = zipcode => createSelector(selectMarket(zipcode), selectMarketMetadata)

export const selectFromMetadata = (zipcode, key) => createSelector(selectMetadataByZip(zipcode), partialRight(get, key))

export const selectMarketIndex = zipcode => selectFromMetadata(zipcode, 'marketIndex')

export const selectCurrentMarket = createSelector(selectZipMap, selectCurrentZipcode, (map, zipcode) => map[zipcode])

export const selectCurrentMarketMetadata = createSelector(
  selectCurrentMarket,
  selectMarketRecordScope,
  selectAffordabilityForMarketMetadata,
  selectMarketMetadata
)

export const selectFromCurrentMarketMetadata = path =>
  createSelector(selectCurrentMarketMetadata, partialRight(get, path))

export const selectCurrentMarketHasMetadata = selectFromCurrentMarketMetadata('enoughData')

export const selectCurrentMarketTemperatureIndex = selectFromCurrentMarketMetadata('marketIndex')

export const selectCurrentMarketType = selectFromCurrentMarketMetadata('marketType')

export const selectCurrentDataRepresentsQuartile = createSelector(
  selectMarketRecordScope,
  scope => scope === METADATA_SCOPES.QUARTILE
)

export const selectCurrentDataRepresentsMutliFamily = createSelector(
  selectCurrentMarketType,
  type => type === MULTI_FAMILY
)

export const selectCurrentMarketData = selectFromCurrentMarketMetadata('data')

export const selectFromCurrentMarketData = path => createSelector(selectCurrentMarketData, partialRight(get, path))

export const selectCurrentDataHistory = selectFromCurrentMarketData('marketHistory')

export const selectCurrentDataPriceMin = createSelector(
  selectAffordabilityForMarketMetadata,
  selectFromCurrentMarketData('priceMin'),
  (pricepoint, metaMin) => Math.min(pricepoint, metaMin)
)

export const selectCurrentDataPriceMax = createSelector(
  selectAffordabilityForMarketMetadata,
  selectFromCurrentMarketData('priceMax'),
  (pricepoint, metaMin) => Math.max(pricepoint, metaMin)
)

export const selectInventoryTotal = selectFromCurrentMarketData('inventoryTotal')

export const selectDaysOnMarketMean = selectFromCurrentMarketData('daysOnMarketMean')

export const selectHomeownerMarketLoading = createSelector(
  state => state.marketRecords.loading,
  state => state.marketRecords.zipMap,
  state => state.marketRecords.error,
  selectZipcode,
  (loading, zipMap, error, zip) => !zipMap[zip] && loading && !error
)

export const selectDetailRequested = state => get(state, 'marketRecords.detailRequested')

export const selectHomeownerMarketDetailRequested = createSelector(
  selectDetailRequested,
  selectZipcode,
  (details, zip) => !!details[zip]
)

export const selectCurrentMarketIndexHistoryByMonth = createSelector(selectCurrentDataHistory, history => {
  return calculateIndexHistoryByMonth(history)
})

export const selectCurrentMarketIndexLatestDelta = createSelector(
  selectCurrentMarketIndexHistoryByMonth,
  monthlyHistory => {
    if (!monthlyHistory || monthlyHistory.length < 2) {
      return undefined
    }

    const sortedHistory = [...monthlyHistory].sort((a, b) => (a.month > b.month ? -1 : 1)) // descending
    const lastDelta = sortedHistory[0].value - sortedHistory[1].value
    return lastDelta
  }
)

export const selectCurrentMarketHasIndexDelta = createSelector(
  selectCurrentMarketIndexLatestDelta,
  delta => !isNaN(delta)
)

export const selectCurrentMarketHasDataForRender = createSelector(
  selectCurrentMarketHasMetadata,
  selectCurrentMarketHasIndexDelta,
  every
)

export const selectCheapestMarket = createSelector(selectCurrentMarket, market => {
  if (!market) return

  const vals = values(market.marketSections.singleFamily)
  const sorted = sortBy(vals, 'priceMin')
  const firstElement = head(sorted)
  return firstElement
})

export const selectLatestMarketValue = createSelector(
  selectCurrentMarketIndexHistoryByMonth,
  historicalMarketValues => {
    return fetchLatestMarketValue(historicalMarketValues)
  }
)

export const selectLatestMarketDemandKey = createSelector(selectLatestMarketValue, value => {
  if (isNaN(value)) {
    return undefined
  }

  if (value > MARKET_VALUE_BREAK_POINTS.VERY_HIGH) {
    return MARKET_DEMAND_KEYS.VERY_HIGH
  } else if (value > MARKET_VALUE_BREAK_POINTS.HIGH) {
    return MARKET_DEMAND_KEYS.HIGH
  } else if (value > MARKET_VALUE_BREAK_POINTS.MODERATE) {
    return MARKET_DEMAND_KEYS.MODERATE
  } else if (value > MARKET_VALUE_BREAK_POINTS.LOW) {
    return MARKET_DEMAND_KEYS.LOW
  } else {
    return MARKET_DEMAND_KEYS.VERY_LOW
  }
})

// NEW! Quartile Selectors
export const selectQuartileMarketRecordScope = _state => {
  return 'QUARTILE'
}

export const selectCurrentQuartileMarketMetadata = createSelector(
  selectCurrentMarket,
  selectQuartileMarketRecordScope,
  selectAffordabilityForMarketMetadata,
  selectMarketMetadata
)

export const selectFromCurrentQuartileMarketMetadata = path =>
  createSelector(selectCurrentQuartileMarketMetadata, partialRight(get, path))

export const selectCurrentQuartileMarketData = selectFromCurrentQuartileMarketMetadata('data')

export const selectFromCurrentQuartileMarketData = path =>
  createSelector(selectCurrentQuartileMarketData, partialRight(get, path))

export const selectCurrentQuartileDataHistory = selectFromCurrentQuartileMarketData('marketHistory')

export const selectCurrentMarketQuartileIndexHistoryByMonth = createSelector(
  selectCurrentQuartileDataHistory,
  history => {
    return calculateIndexHistoryByMonth(history)
  }
)

export const selectLatestQuartileMarketValue = createSelector(
  selectCurrentMarketQuartileIndexHistoryByMonth,
  historicalMarketValues => {
    return fetchLatestMarketValue(historicalMarketValues)
  }
)
