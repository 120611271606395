import { createSelector } from 'reselect'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import get from 'lodash/get'
import { none } from '../utils'
import { selectClientId } from '../client'
import { selectHomeId } from './.'
import { PATH_HELPERS } from '../../../constants/navigation'

export const selectCreateHomeCreating = state => state.homeCreate.creating
export const selectCreateHomeCompiling = state => state.homeCreate.compiling
export const selectCreateHomeCreated = state => state.homeCreate.created
export const selectCreateHomeTimeout = state => state.homeCreate.timeout

export const selectCreateAndLoadHomeComplete = createSelector(selectCreateHomeCreating, selectCreateHomeCompiling, none)

export const selectCreateHomeError = createSelector(
  state => state.homeCreate.error,
  error => {
    if (!error) return undefined

    if (isString(error)) return { [error]: true }

    if (isArray(error)) {
      return error.reduce((acc, e) => {
        acc[e.id] = e
        return acc
      }, {})
    }

    return undefined
  }
)

const createErrorSelector = id => createSelector(selectCreateHomeError, errors => get(errors, id))

export const selectAddressAlreadyExistsError = createErrorSelector('address-street#home-with-address-already-exists')

export const selectCreatedHomeRoute = createSelector(selectClientId, selectHomeId, (clientId, homeId) =>
  PATH_HELPERS.home.buildPath(clientId, homeId)
)
