import { atom } from 'recoil'
import { StateKeys } from '../../../../../constants/stateKeys'
import { StorageKey } from '../../../../../constants/StorageKey'
import { storageEffect } from '../../../../../effects/storageEffect'

export interface HomeSearchMapStateType {
  displayName: string
  center: {
    lat?: number
    lng?: number
  }
  zoom: number
  bounds?: object
}

export const homeSearchMapState = atom<HomeSearchMapStateType | undefined>({
  key: StateKeys.homeSearchMapState,
  default: undefined
})
