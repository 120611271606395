import { getThe, select } from './utils'
import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

// Constants
import { BUYER_COMPLETED_ONBOARDING, GOALS } from '../../constants/client/onboarding'

// Selectors
import {
  selectAffordability as selectBuyerAffordability,
  selectAffordabilityIsDefault as selectBuyerAffordabilityIsDefault
} from './buyerInfo'
import { selectBuyerNewHomeBudgetWithCap } from './home/equity/newHome'
import { selectRentalPropertyBudget } from './home/equity/rentalProperty'
import { selectShowLowConfidenceWarning, selectShowNonDisclosureStateWarning } from './home/metadata'
import { selectCreateHomeCreated, selectCreateHomeCreating, selectCreateHomeError } from './home/homeCreate'
import { selectHasProcessedHome, selectHomeHasAvmsAndLoans, selectHomeError } from './home/index'

const getBuyerOnboarding = state => state.buyerOnboarding
const selectFromBuyerOnboarding = select(getBuyerOnboarding)

export const selectBuyerOnboardingState = createSelector(
  selectFromBuyerOnboarding(getThe('onboardingState')),
  state => state
)

export const selectBuyerOnboardingAddress = createSelector(
  selectFromBuyerOnboarding(getThe('address')),
  source => source
)

export const selectBuyerOnboardingGoal = createSelector(selectFromBuyerOnboarding(getThe('goal')), goal => goal)

export const selectAverageMedianPrice = createSelector(selectFromBuyerOnboarding(getThe('price')), price => price)

export const selectNeedsBuyerOnboarding = createSelector(selectBuyerOnboardingState, buyerOnboardingState =>
  Boolean(buyerOnboardingState && buyerOnboardingState !== BUYER_COMPLETED_ONBOARDING)
)

export const selectHomeCreatedProcessedAndHasRequiredInfo = createSelector(
  selectCreateHomeError,
  selectHomeError,
  selectCreateHomeCreated,
  selectHasProcessedHome,
  selectHomeHasAvmsAndLoans,
  (homeCreateError, homeProcessingError, homeCreated, homeProcessed, requiredHomeDataIsPresent) => {
    if (homeCreateError || homeProcessingError) {
      return false
    }

    return homeCreated && homeProcessed && requiredHomeDataIsPresent
  }
)

export const selectShowHomeEquityRelatedPrice = createSelector(
  selectShowNonDisclosureStateWarning,
  selectShowLowConfidenceWarning,
  selectHomeCreatedProcessedAndHasRequiredInfo,
  selectBuyerOnboardingGoal,
  (nonDisclosure, lowConfidence, homeCreatedProcessedAndHasRequiredInfo, buyerOnboardingGoal) => {
    if (nonDisclosure || lowConfidence || !homeCreatedProcessedAndHasRequiredInfo) {
      return false
    }

    return [GOALS.GOAL_EXPLORE, GOALS.GOAL_NEW_HOME, GOALS.GOAL_INVEST].includes(buyerOnboardingGoal)
  }
)

export const selectHasCitysAvgMedianPrice = createSelector(
  selectShowNonDisclosureStateWarning,
  selectShowLowConfidenceWarning,
  selectFromBuyerOnboarding(getThe('error')),
  (nonDisclosure, lowConfidence, error) => {
    if (nonDisclosure || lowConfidence) {
      return true
    }

    return isEmpty(error)
  }
)

export const selectBuyerOnboardingPricePoint = createSelector(
  selectBuyerAffordability,
  selectBuyerAffordabilityIsDefault,
  selectShowNonDisclosureStateWarning,
  selectShowLowConfidenceWarning,
  selectHomeCreatedProcessedAndHasRequiredInfo,
  selectBuyerOnboardingGoal,
  selectBuyerNewHomeBudgetWithCap,
  selectRentalPropertyBudget,
  selectAverageMedianPrice,
  (
    buyerAffordability,
    affordabilityDefaulted,
    nonDisclosure,
    lowConfidence,
    homeCreatedProcessedAndHasRequiredInfo,
    buyerOnboardingGoal,
    buyerNewHomeBudgetWithCap,
    rentalPropertyBudget,
    averageMedianPrice
  ) => {
    if (!affordabilityDefaulted) {
      return buyerAffordability
    }

    if (nonDisclosure || lowConfidence || !homeCreatedProcessedAndHasRequiredInfo) {
      return averageMedianPrice
    }

    if (homeCreatedProcessedAndHasRequiredInfo) {
      switch (buyerOnboardingGoal) {
        case GOALS.GOAL_NEW_HOME:
        case GOALS.GOAL_EXPLORE:
          return buyerNewHomeBudgetWithCap
        case GOALS.GOAL_INVEST:
          return rentalPropertyBudget
        default:
          return averageMedianPrice
      }
    }
  }
)

export const selectHomeValueCalcuationsStillProcessing = createSelector(
  selectCreateHomeError,
  selectHomeError,
  selectCreateHomeCreated,
  selectHasProcessedHome,
  selectHomeHasAvmsAndLoans,
  selectBuyerNewHomeBudgetWithCap,
  selectRentalPropertyBudget,
  (
    homeCreateError,
    homeProcessingError,
    homeCreated,
    homeProcessed,
    requiredHomeDataIsPresent,
    buyerNewHomeBudgetWithCap,
    rentalPropertyBudget
  ) => {
    // Probably redundant but well worth it
    if (homeCreateError || homeProcessingError) {
      return false
    }

    if (homeCreated && !homeProcessed) {
      return true
    }

    if (homeCreated && homeProcessed && !requiredHomeDataIsPresent) {
      return false
    }

    if (homeCreated && homeProcessed && requiredHomeDataIsPresent) {
      return !(!!buyerNewHomeBudgetWithCap && !!rentalPropertyBudget)
    }
  }
)

export const selectBuyerOnboardingFinalStepLoading = createSelector(
  selectBuyerOnboardingPricePoint,
  selectCreateHomeCreating,
  selectHomeValueCalcuationsStillProcessing,
  (pricePoint, homeCreating, homeValueCalculationsStillProcessing) => {
    return pricePoint === null || homeCreating || homeValueCalculationsStillProcessing
  }
)
