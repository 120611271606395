import get from 'lodash/get'
import map from 'lodash/map'
import compact from 'lodash/compact'
import partial from 'lodash/partial'
import { SINGLE_FAMILY } from './index'

export const minMaxSqFt = (market = {}, marketType = SINGLE_FAMILY) => {
  const marketOrEmpty = market || {}
  const getted = get(marketOrEmpty, ['marketSections', marketType], [])
  const mapped = map(getted, 'sqftMedian')
  const compacted = compact(mapped)

  const formatRecords = records =>
    records.length ? [Math.min(...records) * 0.75, Math.max(...records) * 1.25] : [0, 0]

  const formatted = formatRecords(compacted)
  return formatted
}

export const ensureAffordableInMinMax = (affordableSqFt, [min, max]) =>
  [partial(Math.min, max), partial(Math.max, min), Math.round].reduce((sum, f) => f(sum), affordableSqFt)

const sqftAfford = (affordability, quartile, marketType, market) => {
  const minMax = minMaxSqFt(market)
  const { priceMedian, sqftMedian } = quartile

  const medianPriceSqFt = priceMedian / sqftMedian || 0
  const affordableSqFt = affordability / medianPriceSqFt || 0

  const ensured = ensureAffordableInMinMax(affordableSqFt, minMax)

  return ensured
}

export default sqftAfford
