import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'
import { add } from 'date-fns'
import { selectAuthedAsClient } from './auth'
import {
  selectHasRecentLoan,
  selectHomeLoading,
  selectValuationBounds,
  selectLatestValuationHasConfidence
} from './home'
import { selectMortgageLoadingState } from './mortgageRate'
import { selectValuationHistoryLoading } from './valuationHistory'
import { selectRefiDetails } from './refinance'
import { selectRefiEligible } from './home/loans'
import { selectInsuranceModuleEnabled, selectYearsSincePurchase } from './homeInsurance'
import {
  selectLeverageableEquityForLTV,
  selectEmergencyReservePool,
  selectQualifiedEquityListings,
  CASHOUT_LTV,
  CASHOUT_MINIMUM,
  EMERGENCY_RESERVE_MINIMUM,
  PURCHASING_POWER_LTV,
  PURCHASING_POWER_MINIMUM
} from './home/equity'
import { HOT_MARKET_VALUE_BREAK_POINT } from '../../constants/marketRecords'
import {
  selectCustomerIsAgent,
  selectCustomerHasSponsor,
  selectHasSmsOutreachFeature,
  selectCustomerProfileLoading,
  selectHomebotListingsFeatureEnabled
} from './customerProfile'
import { selectSponsorshipYearsInIndustry } from './sponsorship'
import {
  selectHomeownerMarketLoading,
  selectHomeownerMarketDetailRequested,
  selectCurrentMarketHasDataForRender,
  selectCurrentMarketHasMetadata,
  selectLatestQuartileMarketValue
} from './marketRecord'
import { equitySearchTable } from '../../constants/equity'
import {
  selectLoadingState,
  selectClientRequestedUnsubscribe,
  selectClientIsSubscribedToBuy,
  selectClientId,
  selectSnoozedAvmHotMarketWarningDate
} from './client'
import { selectNeedsBuyerOnboarding } from './buyerOnboarding'
import { selectLoadingVideos } from './videos'
import { selectRecommendations, selectLoadingRecommendations } from './recommendations'
import { selectClientSnoozedReminder } from './home/answers'
import { selectShouldYouSellLoading, selectAppreciationRelevancy, selectCashPositionRelevant } from './shouldYouSell'
import { some, every } from './utils'

export const selectHomeDigestLoading = createSelector(
  selectHomeLoading,
  selectMortgageLoadingState,
  selectValuationHistoryLoading,
  selectLoadingVideos,
  some
)

export const selectBuyerDigestLoading = createSelector(selectCustomerProfileLoading, some)

export const selectNavbarLoading = createSelector(
  selectHomeLoading,
  selectMortgageLoadingState,
  selectValuationHistoryLoading,
  selectLoadingVideos,
  selectLoadingRecommendations,
  some
)

export const selectShouldRenderRefiOptions = createSelector(
  selectHasRecentLoan,
  selectRefiDetails,
  (hasRecentLoan, refiDetails) => !hasRecentLoan && refiDetails && refiDetails.length > 0
)

export const selectShouldRenderNegativeRefiOptionInfoMessage = createSelector(
  selectHasRecentLoan,
  selectRefiDetails,
  (hasRecentLoan, refiDetails) => {
    const refiDetailsWithPositiveSavings = refiDetails.filter(refi => refi.datapoints.some(data => data.y > 0))
    return !hasRecentLoan && refiDetailsWithPositiveSavings?.length === 0
  }
)

export const selectShouldRenderCashout = createSelector(
  selectRefiEligible,
  selectLeverageableEquityForLTV(CASHOUT_LTV),
  (eligible, excess) => eligible && excess >= CASHOUT_MINIMUM
)

export const selectShouldRenderPurchasingPower = createSelector(
  selectRefiEligible,
  selectLeverageableEquityForLTV(PURCHASING_POWER_LTV),
  (eligible, excess) => eligible && excess >= PURCHASING_POWER_MINIMUM
)

export const selectShouldRenderEmergencyReserve = createSelector(
  selectShouldRenderCashout,
  selectEmergencyReservePool,
  (showCashout, pool) => showCashout && pool >= EMERGENCY_RESERVE_MINIMUM
)

export const selectShouldRenderMarketPanel = createSelector(
  selectHomeownerMarketLoading,
  selectHomeownerMarketDetailRequested,
  selectCurrentMarketHasDataForRender,
  (loading, loaded, hasEnoughData) => loading || (loaded && hasEnoughData) // TODO: build FF?
)

export const selectShouldRenderAvmHotMarketPanel = createSelector(
  selectHomeownerMarketLoading,
  selectHomeownerMarketDetailRequested,
  selectLatestQuartileMarketValue,
  (loading, loaded, latestMarketValue) => loading || (loaded && latestMarketValue >= HOT_MARKET_VALUE_BREAK_POINT)
)

export const selectShouldRenderEquitySmartSearchFeatures = createSelector(
  selectShouldRenderMarketPanel,
  state => state.pmi.ltcvPercent,
  (marketsPanel, ltcvPercent) => {
    return (
      marketsPanel &&
      ltcvPercent <= equitySearchTable.LTV_MAX_PERCENT_FOR_ESS &&
      ltcvPercent >= equitySearchTable.LTV_MIN_PERCENT_FOR_ESS
    )
  }
)

export const selectShouldRenderEquitySmartSearchListings = createSelector(
  selectHomebotListingsFeatureEnabled,
  selectQualifiedEquityListings,
  (showListings, qualifiedListings) => {
    return showListings && qualifiedListings && qualifiedListings.length >= 3
  }
)

export const selectShouldRenderSmsOptInFlow = createSelector(selectHasSmsOutreachFeature, hasFeature =>
  Boolean(hasFeature)
)

export const selectShouldRenderBuyerOnboarding = createSelector(
  selectNeedsBuyerOnboarding,
  selectLoadingState,
  selectClientRequestedUnsubscribe,
  selectClientIsSubscribedToBuy,
  selectClientId,
  selectAuthedAsClient,
  (needsOnboarding, loading, flagForUnsub, subscribedToBuy, clientId, isAuthedAsClient) => {
    return needsOnboarding && !loading && !flagForUnsub && subscribedToBuy && !isEmpty(clientId) && isAuthedAsClient
  }
)

export const selectShouldRenderHomeInsuranceModule = createSelector(
  selectInsuranceModuleEnabled,
  selectYearsSincePurchase,
  selectClientSnoozedReminder,
  (display, years, snoozed) => display && years > 0 && !snoozed
)

export const selectShouldAttemptRenderRecommendations = createSelector(
  selectRecommendations,
  recs => Array.isArray(recs) && recs.length > 1
)

export const shouldRenderSponsorBrandingStats = createSelector(
  selectCustomerIsAgent,
  selectCustomerHasSponsor,
  selectSponsorshipYearsInIndustry,
  every
)

export const selectShouldDisplayWhatSellFor = createSelector(
  selectValuationBounds,
  selectLatestValuationHasConfidence,
  (bounds, hasConfidence) => Boolean(bounds && bounds.low && bounds.high && hasConfidence)
)

export const selectShouldDisplayShouldYouSell = createSelector(
  selectAppreciationRelevancy,
  selectCashPositionRelevant,
  selectCurrentMarketHasMetadata,
  selectShouldYouSellLoading,
  selectHomeownerMarketLoading,
  (appreciation, cash, market, loadingShouldYouSell, loadingMarket) => {
    if (loadingShouldYouSell) {
      return true
    }

    const neededCount = loadingMarket ? 1 : 2

    let relevantCount = 0
    if (appreciation) {
      relevantCount++
    }
    if (cash) {
      relevantCount++
    }
    if (market) {
      relevantCount++
    }

    return relevantCount >= neededCount
  }
)

export const selectShouldRenderThinkingOfSelling = createSelector(
  selectRefiEligible,
  selectAppreciationRelevancy,
  selectCashPositionRelevant,
  selectCurrentMarketHasMetadata,
  selectShouldYouSellLoading,
  selectHomeownerMarketLoading,
  (refiEligible, appreciation, cash, market, loadingShouldYouSell, loadingMarket) => {
    if (loadingShouldYouSell || !refiEligible) {
      return false
    }

    const neededCount = loadingMarket ? 1 : 2

    let relevantCount = 0
    if (appreciation) {
      relevantCount++
    }
    if (cash) {
      relevantCount++
    }
    if (market) {
      relevantCount++
    }

    return relevantCount >= neededCount
  }
)

export const selectShouldRenderAvmHotMarketWarning = createSelector(
  selectLatestQuartileMarketValue,
  selectSnoozedAvmHotMarketWarningDate,
  (marketValue, date) => {
    const isHot = marketValue >= HOT_MARKET_VALUE_BREAK_POINT
    if (!isHot) {
      return false
    }

    if (isHot && !date) {
      return true
    }

    const oldSnooze = date && add(date, { months: 5 }) < new Date()
    return isHot && !!oldSnooze
  }
)
