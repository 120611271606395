import groupBy from 'lodash/groupBy'
import values from 'lodash/values'
import maxBy from 'lodash/maxBy'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import emptyQuartile from '../../../../constants/emptyQuartile'

const DEFAULT_COMPOSITE = 'singleFamilyComposite'

const quartile = (affordability, marketType, market) => {
  if (!market || !affordability) {
    return {}
  }

  let targetQuartile

  const marketSections = market.marketSections[marketType]

  if (!marketSections || !marketSections.length) {
    // No quartile data

    const attemptedComposite = market[`${marketType}Composite`] || market[DEFAULT_COMPOSITE]

    return attemptedComposite || emptyQuartile()
  }

  const grouped = groupBy(marketSections, 'city')
  const vals = values(grouped)
  const maxedBy = maxBy(vals, sections => sections.reduce((sum, { inventoryTotal = 0 }) => sum + inventoryTotal, 0))

  const sorted = sortBy(maxedBy, 'priceMin')

  const setQuartile = ([firstQuartile, ...rest]) => {
    targetQuartile = firstQuartile
    return rest
  }

  const rest = setQuartile(sorted)

  find(rest, quartile => {
    if (quartile.priceMin > affordability) {
      return true
    }

    targetQuartile = quartile
    return false
  })

  return targetQuartile
}

export default quartile
